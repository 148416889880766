import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import queryString from 'query-string';
import Alert from '@mui/material/Alert';
import { Box, Button, Collapse, IconButton } from "@mui/material";

import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../../utils/firebase.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { productInputs, userInputs } from "../../formSource";
import { useLocation } from "react-router-dom";
 import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AuthContext from "../../context/AuthContext"
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { onSnapshot, updateDoc,increment } from "firebase/firestore";
import React from "react";



const New = () => {

  const [file, setFile] = useState("");
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [incrimentNumber, setIncrimentNumber] = useState(0);
  const [incrimentMessageNumber, setIncrimentMessageNumber] = useState(0);

  const [categorySelected, setCategorySelected] = useState('');
  const [subCategorySelected, setsubCategorySelected] = useState('');
  //let [inputs] = {userInputs};
  const [title] = "test";
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [isIncriment, setIncriment] = React.useState(false);
  const [disableSaveButton, setdisableSaveButton] = React.useState(true);
  

  //let a = queryString.parse(location.search)


  const search = window.location.search;
  const params = new URLSearchParams(search);
  const foo = params.get('id');
  console.log(params);
 
  console.log(user)
  if (!user) {
    history.push("/pageNotFound");
  }

  //const navigate = useNavigate()
  let location = useLocation();
  let inputData;

  location.pathname === "/addproducts" ? inputData = productInputs : inputData = userInputs;

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload 1 is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, image: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file1.name;
      const storageRef = ref(storage, file1.name);
      const uploadTask = uploadBytesResumable(storageRef, file1);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload 2 is" + progress + "% done");
          //setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, image1: downloadURL }));
          });
        }
      );
    };
    file1 && uploadFile();
  }, [file1]);
  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file2.name;
      const storageRef = ref(storage, file2.name);
      const uploadTask = uploadBytesResumable(storageRef, file2);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload 3 is " + progress + "% done");
          //setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, image2: downloadURL }));
          });
        }
      );
    };
    file2 && uploadFile();
  }, [file2]);
  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file3.name;
      const storageRef = ref(storage, file3.name);
      const uploadTask = uploadBytesResumable(storageRef, file3);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload 4 is " + progress + "% done");
          //setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, image3: downloadURL }));
          });
        }
      );
    };
    file3 && uploadFile();
  }, [file3]);

  const handleInput = (e) => {
    setdisableSaveButton(false);
    const id = e.target.id;
    const value = id == 'price' ? e.target.valueAsNumber : e.target.value;

    setData({ ...data, [id]: value });
  };
  const handleCategoryInput = (e) => {
    const id = "category"
    const value = e.target.value;
    setCategorySelected(value);

    setData({ ...data, [id]: value });

  };

  const handleSubCategoryInput = (e) => {
    const id = "subcategory"
    const value = e.target.value;
    setsubCategorySelected(value);

    setData({ ...data, [id]: value });
  };

  const addProduct = async (e) => {
    const res = await addDoc(collection(db, "products"), {
      data,
    });
 
    await setDoc(doc(db, "products", res.id), {
      ...data,
      timeStamp: serverTimestamp(),
      productId: incrimentNumber
    });
   
   
   
  }
  useEffect( () => {
    if(incrimentNumber !== 0 && isIncriment){
      addProduct();
      setOpen(true);
      setIncriment(false);
      setIncrimentNumber(0);
     
    }
   
  });
  useEffect(() => {
    if(isIncriment){
   
    const unsub = onSnapshot(
      collection(db, "incriments"),
      async (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        //list = list.filter((listItem) => listItem.id == urlParam.productId.trim())
        setIncrimentNumber(list[0].incriment_number);
        setIncrimentMessageNumber(list[0].incriment_number);
        
      
    
      });
    return () => {
     
      setIncriment(false);
      unsub();
    };
  }
  });
    const handleAdd = async (e) => {
      e.preventDefault();
      setdisableSaveButton(true);
      try {
        if (location.pathname === "/addproducts") {
          const docRef = doc(db, 'incriments', 'vtWuQlPqkuWk2CVfTy6Z');
          const updateIncricment = await updateDoc(docRef, {
            incriment_number: increment(1)
          });
          setIncriment(true);
          
        }
        else {
          const res = await createUserWithEmailAndPassword(
            auth,
            data.email,
            data.password
          );
          await setDoc(doc(db, "users", res.user.uid), {
            ...data,
            timeStamp: serverTimestamp(),
          });
        }
  
        //navigate(-1)
      } catch (err) {
        console.log(err);
      }
    };
 
  

  return (

    <div className="new">
      <Sidebar />

      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
          <Collapse in={open}>
            <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                
              >
               </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Product : {incrimentMessageNumber} added successfully!
          </Alert>
          </Collapse>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>

          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Photo 1: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
                <label htmlFor="file1">
                  Photo 2: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file1"
                  onChange={(e) => setFile1(e.target.files[0])}
                  style={{ display: "none" }}
                />
                <label htmlFor="file2">
                  Photo 3: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file2"
                  onChange={(e) => setFile2(e.target.files[0])}
                  style={{ display: "none" }}
                />
                <label htmlFor="file3">
                  Photo 4: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file3"
                  onChange={(e) => setFile3(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              {inputData.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type != "dropdown" ? (
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                    />) :
                    <> {input.id == "category" ? (
                      <div>
                        <Select id={input.id}
                          labelId="demo-simple-select-label"
                          value={categorySelected}
                          label="Category"
                          onChange={handleCategoryInput}
                        >
                          <MenuItem value="New Arrivals">New Arrivals</MenuItem>
                          <MenuItem value="Bangles">Bangles</MenuItem>
                          <MenuItem value="Necklace">Necklace</MenuItem>
                          <MenuItem value="Chain">Chain Brancelet</MenuItem>
                          <MenuItem value="Brancelet">Chain Brancelet</MenuItem>
                          <MenuItem value="Anklet">Anklet</MenuItem>
                          <MenuItem value="Dropes">Dropes</MenuItem>
                          <MenuItem value="Ring">Ring</MenuItem>
                          <MenuItem value="Name Locket">Name Locket</MenuItem>
                          <MenuItem value="Name Ring">Name Ring</MenuItem>
                        </Select>
                      </div>
                    ) : (
                      <div>
                        <Select id={input.id}
                          labelId="demo-simple-select-label"
                          value={subCategorySelected}
                          label="Sub Category"
                          onChange={handleSubCategoryInput}
                        >
                          <MenuItem value="Antique">Antique</MenuItem>
                          <MenuItem value="Turkish">Turkish</MenuItem>
                          <MenuItem value="Chettinadu">Chettinadu</MenuItem>
                          <MenuItem value="Kolkata">Kolkata</MenuItem>
                          <MenuItem value="Bombay">Bombay</MenuItem>
                          <MenuItem value="Rajkot">Rajkot</MenuItem>
                          <MenuItem value="Kerala">Kerala</MenuItem>
                          <MenuItem value="Handmad Chains">Handmad Chains</MenuItem>
                          <MenuItem value="Italian">Italian</MenuItem>
                          <MenuItem value="Mechiene Chains">Mechiene Chains</MenuItem>
                          <MenuItem value="Rdium Ball Chains">Rdium Ball Chains</MenuItem>
                          <MenuItem value="Cnc Balls">Cnc Balls</MenuItem>
                          <MenuItem value="Molding">Molding</MenuItem>
                          <MenuItem value="Casting">Casting</MenuItem>
                          <MenuItem value="General ">General </MenuItem>
                          <MenuItem value="CNC">CNC</MenuItem>
                          <MenuItem value="Rodium Ball">Rodium Ball </MenuItem>
                          <MenuItem value="Nelluri">Nelluri </MenuItem>
                          <MenuItem value="Stone Ring">Stone Ring </MenuItem>
                        </Select>
                      </div>
                    )
                    }
                    </>
                  }
                </div>

              ))}

              <button disabled={disableSaveButton}  type="submit">
                Save
              </button>

            </form>

          </div>

        </div>
      </div>

    </div>
  );
};

export default New;
